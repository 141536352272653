$enable-negative-margins: true;
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes wave {

    0%,
    40%,
    100% {
        transform: initial;
        background: rgba(0, 0, 0, 0.6);
    }

    20% {
        transform: translateY(-8px);
        background: rgba(0, 0, 0, 0.3);
    }
}

@keyframes listening {
    0% {
        box-shadow: 0 0 0 1px rgba(var(--bs-primary-rgb), 0.5);
    }

    50% {
        box-shadow: 0 0 0 2px rgba(var(--bs-primary-rgb), 0.9);
    }

    100% {
        box-shadow: 0 0 0 1px rgba(var(--bs-primary-rgb), 0.5);
    }
}

body {
    background-color: #eee;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.App {
    min-height: 100vh;
}

.f-12 {
    font-size: 0.75rem;
}

.message-list {
    height: 500px;
    min-height: 500px;
}

.form-switch {
    .form-check-input {
        height: 1.3em;
        margin-top: 0.05em;
    }
}

.form-control {
    height: 48px;
    font-size: 14px;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
}

.btn {
    border-radius: 1rem;
}

.card {
    border: none;
    border-radius: 1rem;
    @extend .shadow-sm;

    .card-header {
        height: 64px;
        display: flex;
        align-items: center;
        border: none;
        @extend .shadow-sm;
    }

    .card-body {
        border: none;
    }

    .card-footer {
        box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        padding: 1rem 1rem;
        border: none;
    }
}

.bubble {
    display: inline-block;
    text-align: start;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 16px;
    white-space: pre-wrap;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    max-width: 80%;

    &.dictation {
        background-color: #cfdaff;
    }
}

.bubble-profile-image {
    min-width: 33px;
    width: 33px;
    height: 33px;
    border-radius: 50em;
    border: 1px solid #dddddd;
}

.sender {
    text-align: end;

    .bubble {
        background-color: #fabfab;
    }
}

.receiver {
    .bubble {
        background-color: #eee;
    }

    .bubble-profile-image {}
}

.typing {
    display: flex;
    gap: 3px;
    padding: 4px 16px;

    .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.6);
        animation: wave 2s linear infinite;

        &:nth-child(2) {
            animation-delay: -1.8s;
        }

        &:nth-child(3) {
            animation-delay: -1.8s;
        }
    }
}

.react-select {
    &-container {
        width: 220px;
        max-width: 220px;
    }

    &__option {
        background-color: #eee;
    }

}

.place-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-38 {
    width: 38px;
}

.h-38 {
    height: 38px;
}

.size-38 {
    @extend .w-38;
    @extend .h-38;
    @extend .place-center;
    border-radius: 50%;
    padding: 0.25rem;
}

.message-box {
    min-height: 42px !important;
    resize: none;
    padding-top: 0.5rem;
    padding-right: 48px;
}

.mic-btn {
    font-size: 20px;

    &:active {
        border-color: transparent !important;
    }

    &.listening {
        animation: listening 2s infinite;
    }
}

.signup-wrap {
    background: linear-gradient(90deg, #56ccf2, #56cbf293);
    min-height: 100vh;
}

.password-toggle {
    position: relative;

    input {
        padding-right: 2.5rem;
    }

    .password-toggle-btn {
        position: absolute;
        right: 10px;
        top: 13px;
        color: darkgray !important;

        &:hover {
            color: gray !important;
        }
    }
}

.mw-120 {
    min-width: 120px;
}

.tab-content {
    padding: 1.5rem 0;
}

.size-42 {
    width: 42px;
    height: 42px;

    &.btn:active {
        border: none;
    }
}

.size-100 {
    width: 100px;
    height: 100px;
}

.size-170 {
    width: 170px;
    height: 170px;
}

.placeholder-center {
    &::placeholder {
        text-align: center;
    }
}

.nav-tabs .nav-link {
    color: #000;
    margin-bottom: 0;
    border: 2px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #000;
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: var(--bs-primary);
}

.home-btn {
    cursor: pointer;
}

.logo {
    align-self: center;
    height: auto;
    width: 120px;
}

.headerlogo {
    font-size: 18px;

    img {
        max-height: 35px;
    }
}


.audio-message {
    height: 35px;

    @include media-breakpoint-down(sm) {
        width: 200px;
    }

    @media (max-width: 390px) {
        width: 165px;
    }
}

#playlist {
    max-height: 400px;
    overflow-y: scroll;
    list-style: none;
    padding-left: 0;
}

#playlist li:hover {
    background-color: rgba(255, 0, 0, 0.295);
}

#playlist li:first-child {
    border-left: 2px blue solid;
    max-height: 0;
    opacity: 0;
    animation: grow 1s ease-in-out forwards;
}

#playlist li {
    padding-left: 5px;
}

@keyframes grow {
    to {
        max-height: 100px;
        opacity: 1;
    }
}

.delete-confirm {
    position: fixed;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    .delete-confirm-inner {
        width: 100%;
        max-width: 500px;
        background: #f5f6fd;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

        .delete-confirm-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 32px;
        }
    }
}

.mic-spinner {
    --bs-spinner-width: 40px;
    --bs-spinner-height: 40px;
    --bs-spinner-border-width: 2px;
    background-color: #e9ecef8c;
}

.mic {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    box-shadow: 0 2px 5px #bebebe;
    color: #000;
    font-size: 16px;
    position: relative;
    z-index: 1;
    border: none;

    .pulse-ring {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: transparent;
        border: 2px solid transparent;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

        &.low-energy {
            border-color: rgba(0, 0, 0, 0.8);
            animation: pulsate 2s infinite;
        }

        &.high-energy {
            border-color: rgba(0, 0, 0, 0.8);
            animation: pulsate 0.5s infinite;
        }
    }

    &:active {
        border-color: transparent !important;
    }
}

@keyframes pulsate {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1, 1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(-50%, -50%) scale(1.3, 1.3);
        opacity: 0;
    }
}